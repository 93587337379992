import * as React from "react";

import { SumUp } from "./SumUp";
import { Price } from "./Price";
import { formatCurrency } from "../../components/helpers/currency";
import { selectCartItemProperty } from "../../orders";
import { imgProps, ProductImageSize } from "../../product/productImage";
import { OrderServiceProps, DataProps } from "../../orders";
import { theplant } from "../../proto";
import { prefectures } from "../../orders/prefectureData";
import { getDate } from "../shared/helper";
import { GiftWrappingModal } from "./GiftWrapping";
import { getAmountToFreeDelivery } from "../helper";
type IOrder = theplant.ec.service.orders.IOrder;
type IOrderItem = theplant.ec.service.orders.IOrderItem;
interface CartSummaryProps {
  cart?: IOrder | null;
  checkout?: OrderServiceProps["checkout"];
  data?: DataProps["data"]["data"];
  isAmazonPay?: boolean;
  noItems?: boolean;
}

const OrderItem = ({ orderItem }: { orderItem: IOrderItem }) => {
  const productCode = orderItem.productCode;
  const colorCode = selectCartItemProperty(orderItem, "ColorCode");
  const productPath = `/products/${productCode}/${colorCode}`;

  const productName = orderItem.productName;
  const colorName = selectCartItemProperty(orderItem, "ColorName");
  const japanSize = selectCartItemProperty(orderItem, "JapanSize");
  const size = selectCartItemProperty(orderItem, "Size");
  const europeSize = selectCartItemProperty(orderItem, "EuropeSize");

  return (
    <div className="product-tile cart-items_item">
      <figure className="img">
        <a href={productPath} target="_blank" rel="noopener noreferrer">
          <img
            {...imgProps(orderItem.productImageUrl || "", ProductImageSize.MN)}
            alt={productName || ""}
          />
        </a>
      </figure>
      <div className="product-tile_info">
        <div className="name-color-and-size">
          <h4 title={productName || ""}>
            <a href={productPath} target="_blank" rel="noopener noreferrer">
              {productName}
            </a>
          </h4>
          <Price orderItem={orderItem} />
          <p className="color mt-10">
            <span className="label">カラー：</span>
            {colorName}
          </p>
          <p className="size">
            <span className="label">サイズ：</span>
            {japanSize || europeSize || size}
          </p>
          <div className="quantity">
            <span className="label">数量：</span>
            {orderItem.quantity}
          </div>
        </div>
      </div>
    </div>
  );
};

const Address = ({
  address,
  title,
}: {
  address?: OrderServiceProps["checkout"]["input"]["form"]["shippingAddress"];
  title?: string;
}) =>
  address ? (
    <div className="address-message">
      <p className="title">{title}</p>
      <span className="name">
        {`${address.familyName || address.katakanaFamilyName} ${
          address.givenName || address.katakanaGivenName
        }`}
      </span>
      <br />
      <span className="address">
        {`〒${address.zipcode}
        ${
          (
            prefectures.find(({ english }) => english === address.prefecture) ||
            {}
          ).name
        }
        ${address.city ? address.city : ""}
        ${address.address1 ? address.address1 : ""}
        ${address.address2 ? address.address2 : ""}`}
      </span>
      <br />
      <span>電話番号：{address.phone}</span>
    </div>
  ) : null;

const ArrowSvg = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6.29508L1.41 7.70508L6 3.12508L10.59 7.70508L12 6.29508L6 0.295078L0 6.29508Z"
      fill="black"
    />
  </svg>
);

const CartSummary = ({
  cart,
  checkout,
  data,
  isAmazonPay,
  noItems,
}: CartSummaryProps) => {
  const checkoutData = checkout && checkout.input && checkout.input.form;
  const shippingAddress = checkoutData && checkoutData.shippingAddress;
  const billingAddress = checkoutData && checkoutData.billingAddress;
  const specifyBillingAddress =
    checkoutData && checkoutData.specifyBillingAddress;
  const deliveryInfo = checkoutData && checkoutData.deliveryInfo;
  const specificed = deliveryInfo && deliveryInfo.specified;
  const specifiedDate = deliveryInfo && deliveryInfo.specifiedDate;
  const specifiedHours = deliveryInfo && deliveryInfo.specifiedHours;
  const deliveryMethod = checkoutData?.deliveryMethod;
  let deliveryHour;
  let deliveryDay;
  if (specifiedHours && data && data.deliveryDateRange) {
    if (specifiedDate) {
      deliveryDay = new Date(specifiedDate);
    }

    if (specifiedHours) {
      deliveryHour = (data.deliveryDateRange.hours || []).find(
        ({ value }) => value === specifiedHours
      );
    }
  }
  const giftWrappingSpecified =
    checkoutData && checkoutData.giftWrapping?.specified;

  const storeId = checkoutData && checkoutData.storeId;
  let store;
  if (storeId && data) {
    const { allStores } = data;
    store = allStores?.find(({ id }) => id === storeId);
  }

  const [showCartItems, setShowCartItems] = React.useState(true);
  const [showAddress, setShowAddress] = React.useState(true);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  let amountToFreeDelivery = cart ? getAmountToFreeDelivery(cart) : 0;

  const promotions = cart
    ? cart.namedCoupons?.map((i) => i.name).filter((i): i is string => i != null)
    : [];

  return (
    <div className="cart-summary">
      <div className="sumup-title">ご注文内容</div>
      {noItems ? null : (
        <>
          <div className="divider" />
          <div
            className={`sumup-section-title mb-15 expand-title ${
              showCartItems ? "opened" : ""
            }`}
            onClick={() => {
              setShowCartItems(!showCartItems);
            }}
          >
            合計点数&nbsp;&nbsp;
            {((cart && cart.orderItems) || []).reduce((count, item) => {
              return (count += Number(item.quantity || 0));
            }, 0)}
            点
            <ArrowSvg />
          </div>
          <div
            className={`cart-items expand-content-wrapper ${
              showCartItems ? "opened" : ""
            }`}
          >
            {((cart && cart.orderItems) || []).map((item, idx) => (
              <OrderItem orderItem={item} key={`order-item-${idx}`} />
            ))}
          </div>
        </>
      )}

      <div className="divider" />

      {!isAmazonPay && checkoutData ? (
        <>
          <div
            className={`sumup-secion-title expand-title ${
              showAddress ? "opened" : ""
            }`}
            onClick={() => {
              setShowAddress(!showAddress);
            }}
          >
            配送
            <ArrowSvg />
          </div>
          <div
            className={`expand-content-wrapper ${showAddress ? "opened" : ""}`}
          >
            {(shippingAddress || billingAddress) &&
            deliveryMethod ===
              theplant.ec.api.orders.DeliveryMethod.HOME_DELIVERY ? (
              <>
                <Address address={shippingAddress} title="配送先情報" />
                {specificed && deliveryDay && specifiedHours && (
                  <div className="deliveryDate">
                    <p className="title">配送日時</p>
                    <span>
                      {getDate(deliveryDay).year}年{getDate(deliveryDay).month}
                      月{getDate(deliveryDay).day}日(
                      {getDate(deliveryDay).youbi}
                      )&nbsp;&nbsp;
                      {(deliveryHour || {}).name}
                    </span>
                  </div>
                )}
                {giftWrappingSpecified ? (
                  <div className="giftwrapping ">
                    <p className="title flex space-between align-center full-width">
                      <span>ギフトラッピング（あり）</span>
                      <span
                        className="gift-wrapping-modal-trigger link underline"
                        onClick={() => {
                          openModal();
                        }}
                      >
                        サンプル画像
                      </span>
                    </p>
                    <GiftWrappingModal
                      isModalOpen={isModalOpen}
                      closeModal={closeModal}
                    />
                  </div>
                ) : null}
              </>
            ) : null}

            {store &&
              (deliveryMethod ===
                theplant.ec.api.orders.DeliveryMethod.STORE_PICKUP ||
                deliveryMethod ===
                  theplant.ec.api.orders.DeliveryMethod.CONVENIENCE_PICKUP) && (
                <div className="address-message">
                  <p className="title">店舗受け取り</p>
                  <span className="name">{store.name}</span>
                  <br />
                  <span className="address">
                    {`〒${store.zip} ${store.prefecture} ${store.city} ${store.address}`}
                  </span>
                  <br />
                  <span>電話番号: {store.phone}</span>
                </div>
              )}
            {specifyBillingAddress && billingAddress && (
              <Address address={billingAddress} title="注文者情報" />
            )}
          </div>

          <div className="divider"></div>
        </>
      ) : null}

      <SumUp sumUp={cart && cart.sumUp} promotions={promotions} />

      <p className="delivery-fee-indicator desc text-center">
        {amountToFreeDelivery === 0
          ? "お客様の送料は無料です。"
          : `あと${formatCurrency(amountToFreeDelivery)}で送料無料になります。`}
      </p>
    </div>
  );
};

export { CartSummary };
