import $ from "jquery";

import { DESKTOP_HEADER_BREAKPOINT } from "../constants/Misc";

const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const clearAllBodyScrollLocks = bodyScrollLock.clearAllBodyScrollLocks;

class Header {
  timer;

  constructor() {
    this.bindEvents();

    $(".header").addClass("header--initialized");
  }

  bindEvents() {
    $(".js-hamburger:not(disabled)").bind(
      "click",
      this.handleClickHamburger.bind(this)
    );
    $(".js-header-mobile-top-menu").bind(
      "click",
      this.handleClickTopMenu.bind(this)
    );
    $(".js-header-mobile-sub-menu-item_title").bind(
      "click",
      this.handleClickSubMenu.bind(this)
    );
    $(".js-header-mobile-back-to-top-menu").bind(
      "click",
      this.handleClickBackToTopMenu.bind(this)
    );
    $(".header__top-menu").bind(
      "mouseenter",
      this.handleMouseEnterTopMenu.bind(this)
    );
    $(".js-header-menu").bind("mouseleave", this.handleCloseTopMenu.bind(this));
    $(".js-mobilemenu-close").bind("click", this.closeMenu.bind(this));
    $(".js-header-search-close-button").bind(
      "click",
      this.closeSearchModal.bind(this)
    );
    $(".js-search-modal-trigger").bind(
      "click",
      this.openSearchModal.bind(this)
    );
    $(".js-sub-menu-link").bind("mouseenter", this.showRelatedImage.bind(this));
    $(".js-header-mobile-top-menu").bind(
      "click",
      this.showDefaultImage.bind(this)
    );
    $(".js-header__link-item-cart").on(
      "mouseenter",
      this.showCartWidget.bind(this)
    );
    $(".js-header__link-item-cart").on(
      "mouseleave",
      this.hideCartWidget.bind(this)
    );

    this.switchHeader();
    $(window).scroll(this.switchHeader);

    document.addEventListener("scroll", this.resetHeaderForPC);
    window.addEventListener("resize", this.resetHeaderForPC);
    window.addEventListener("orientationchange", this.resetHeaderForPC);
  }

  switchHeader() {
    if ($(window).scrollTop() <= 0) {
      $(".header").removeClass("header--fixed").addClass("header--opacity-bg");
    } else if ($(".header").hasClass("header--opacity-bg")) {
      $(".header").addClass("header--fixed").removeClass("header--opacity-bg");
    }
  }

  resetHeaderForPC = () => {
    if (
      $(".js-hamburger").hasClass("is-active") &&
      $(window).width() >= DESKTOP_HEADER_BREAKPOINT
    ) {
      this.closeMenu();
      $(".header__nav").removeAttr("style");
      $(".header__sub-menu").removeAttr("style");
    }
  };

  handleClickHamburger(event) {
    const $target = $(event.currentTarget);
    $target.toggleClass("is-active");

    const navElement = document.querySelector(".header__nav");
    if ($target.hasClass("is-active")) {
      alignMobileMenuTopToHeader();

      $(".header").addClass("header--white-bg");
      $("body").addClass("is-mobile-menu-open");
      disableBodyScroll(navElement);
    } else {
      $("body").removeClass("is-mobile-menu-open");
      $(".header").removeClass("header--white-bg");
      clearAllBodyScrollLocks();
    }

    $(".js-header-mobile-top-menu.is-active").removeClass("is-active");
    this.resetAllStatus();
  }

  resetAllStatus() {
    $(".header__backdrop.visible").removeClass("visible");
    $(".header__sub-menu.expand").removeClass("expand");
    $(".header__top-link.visible").removeClass("visible");
  }

  handleClickTopMenu(event) {
    // mobile menu opens
    if ($(".js-hamburger").hasClass("is-active")) {
      event.preventDefault();

      $(".js-header-mobile-top-menu").removeClass("is-active");

      const $target = $(event.currentTarget);
      $target.addClass("is-active");

      const $subMenuTarget = $target.find("~ .header__sub-menu");
      if ($subMenuTarget.length > 0) {
        disableBodyScroll($subMenuTarget[0]);
      }

      if (window.innerWidth < DESKTOP_HEADER_BREAKPOINT) {
        this.displayCategoryImage($target);
      }
    }
  }

  displayCategoryImage($target) {
    const $imageWrapper = $target
      .siblings(".header__sub-menu")
      .find(".header__sub-images-wrapper");

    const $subCategoryImgWrapper = $target
      .siblings(".header__sub-menu")
      .find(".header__sub-menu-back .image-container");

    if ($imageWrapper) {
      const $matchImage = $imageWrapper.find(
        ".header__sub-image-item:not([data-code])"
      );

      if ($matchImage) {
        const $img = $matchImage.find("img");

        $subCategoryImgWrapper.css(
          "background-image",
          `url(https:${$img.data("src")})`
        );
      }
    }
  }

  handleClickSubMenu(event) {
    event.preventDefault();
    const $target = $(event.currentTarget);
    const navElement = $target.next(".header__sub-links-expand-wrapper");
    if (window.innerWidth < DESKTOP_HEADER_BREAKPOINT) {
      this.resetAllStatus();
      // mobile
      if ($target.hasClass("expand")) {
        $target.removeClass("expand");
      } else {
        $target.addClass("expand");
      }
      if (navElement.hasClass("expand")) {
        navElement.removeClass("expand");
      } else {
        navElement.addClass("expand");
      }
    } else {
      // desktop
      if (!$(".header__sub-menu").hasClass("expand")) {
        $(".header__sub-menu").addClass("expand");
      }

      this.clearActiveCategoryTitle();
      $(".header__sub-menu-item-expand-title").addClass("grey");
      $(".header__sub-menu-item.no-child-menu").addClass("grey");
      $target.addClass("active");
      this.showRelatedImage(event, $target.find(".js-sub-menu-link"), true);
    }
  }

  handleClickBackToTopMenu(event) {
    const $target = $(event.currentTarget);
    $target
      .closest(".header__top-menu")
      .find(".js-header-mobile-top-menu")
      .removeClass("is-active");

    clearAllBodyScrollLocks();
    const navElement = document.querySelector(".header__nav");
    disableBodyScroll(navElement);
  }

  handleMouseEnterTopMenu(event) {
    if (window.innerWidth < DESKTOP_HEADER_BREAKPOINT) {
      return;
    }

    if (!$(".header").hasClass("header--white-bg")) {
      $(".header").addClass("header--white-bg");
    }

    if (!$(".header__backdrop").hasClass("visible")) {
      $(".header__backdrop").addClass("visible");
    }

    this.clearActiveCategoryTitle();

    $(".header__sub-menu.expand").removeClass("expand");

    const menuTarget = $(event.currentTarget);

    menuTarget.find(".header__top-link").addClass("visible");
    // clear old visible to fixes the old disapper before new display
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      menuTarget
        .siblings()
        .find(".header__top-link.visible")
        .removeClass("visible");
    }, 100);

    // lazy load sub menu images
    if ($(".js-hamburger").hasClass("is-active")) {
      // no need to display images on mobile
      return;
    }

    const $target = $(event.currentTarget);
    $target.find("img[data-src]").each(function () {
      $(this).attr("src", $(this).data("src"));
      $(this).removeAttr("data-src");
    });

    this.showDefaultImage(event, menuTarget.find(".header__top-link"));
  }

  handleCloseTopMenu(event) {
    if (window.innerWidth < DESKTOP_HEADER_BREAKPOINT) {
      return;
    }

    $(".header").removeClass("header--white-bg");

    $(".js-header-mobile-top-menu.is-active").removeClass("is-active");
    this.resetAllStatus();
    this.clearActiveCategoryTitle();
  }

  clearActiveCategoryTitle() {
    $(".header__sub-menu-item-expand-title.grey").removeClass("grey");
    $(".header__sub-menu-item-expand-title.active").removeClass("active");
    $(".header__sub-menu-item.no-child-menu.grey").removeClass("grey");
  }

  closeMenu() {
    $(".js-hamburger").click();
  }

  onClickOutside = (event) => {
    const el = document.querySelector(".js-search-modal.is-active");
    if (el && !el.contains(event.target)) {
      this.closeSearchModal();
    }
  };

  openSearchModal() {
    $(".js-search-modal").addClass("is-active");
    $("#header-container").addClass("is-search-active");

    // focus on search input
    setTimeout(() => {
      $("input.header__search-input").focus();
      document.addEventListener("click", this.onClickOutside);
    }, 100);
  }

  closeSearchModal(event) {
    if (event) {
      event.preventDefault();
    }

    $(".js-search-modal").removeClass("is-active");
    $("#header-container").removeClass("is-search-active");
    document.removeEventListener("click", this.onClickOutside);
  }

  showRelatedImage(event, target, showSubCategoryImage) {
    const $target = target || $(event.currentTarget);

    if (
      $target.parent().parent().hasClass("header__sub-links-wrapper") &&
      !showSubCategoryImage
    ) {
      return;
    }

    const code = $target.attr("data-code");

    const $relatedImage = $target
      .closest(".header__sub-menu")
      .find(".header__sub-images-wrapper")
      .find(`.header__sub-image-item[data-code='${code}']`);

    $(".header__sub-image-item").removeClass("header__sub-image-item--active");

    if ($relatedImage.length) {
      $relatedImage.addClass("header__sub-image-item--active");
    }
  }

  showDefaultImage(event, target) {
    const $target = target || $(event.currentTarget);

    const $defaultImage = $target
      .siblings(".header__sub-menu")
      .find(".header__sub-images-wrapper")
      .find(".header__sub-image-item:not([data-code])");

    $(".header__sub-image-item").removeClass("header__sub-image-item--active");

    if ($defaultImage.length) {
      $defaultImage.addClass("header__sub-image-item--active");
    }
  }

  showCartWidget(event) {
    const $target = $(event.currentTarget);
    $target.addClass("is-active");

    if (
      $(".header").hasClass("header--opacity-bg") &&
      $("#cart-widget-app .cart-widget").length
    ) {
      $(".header")
        .addClass("header--cart-active")
        .removeClass("header--opacity-bg");
    }
  }

  hideCartWidget(event) {
    const $target = $(event.currentTarget);
    $target.removeClass("is-active");

    $(".header").removeClass("header--cart-active");
    if (!$(".header").hasClass("header--fixed")) {
      $(".header").addClass("header--opacity-bg");
    }
  }
}

export const alignMobileMenuTopToHeader = () => {
  if ($(window).width() >= DESKTOP_HEADER_BREAKPOINT) {
    $(".header__nav").removeAttr("style");
    $(".header__sub-menu").removeAttr("style");
    return;
  }
};

export default Header;
