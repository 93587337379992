import React from "react";
import { formatCurrency } from "../../components/helpers/currency";
import { theplant } from "../../proto";
import { CHECKOUT_SUM_UP_SUB_ITEM_NAME_TO_LABEL_MAP } from "../../constants/Texts";

const SubItemType = theplant.ec.service.accounting.SubItemType;
type ISubItem = theplant.ec.service.accounting.ISubItem;
type ISumUp = theplant.ec.service.accounting.ISumUp;

const selectSumUpItems = (sumUp?: ISumUp | null): ISubItem[] => {
  if (!sumUp) {
    return [];
  }

  const { quoteAmountWithTax, billingAmountWithTax, subItems } = sumUp;

  let displaySubItems: ISubItem[] = [];

  displaySubItems.push({ name: "QUOTE_AMOUNT", amount: quoteAmountWithTax });

  (subItems || [])
    .filter(
      // COUPON_DISCOUNT & PROMOTION_DISCOUNT are contained in TOTAL_DISCOUNT
      ({ subType }) =>
        subType !== SubItemType.COUPON_DISCOUNT &&
        subType !== SubItemType.PROMOTION_DISCOUNT
    )
    .forEach(({ subType, amount, name }) => {
      // Should not display TOTAL_DISCOUNT when the amount is 0,
      // requested at https://github.com/theplant/lacoste3/issues/1616#issuecomment-455094556.
      if (subType === SubItemType.TOTAL_DISCOUNT && Number(amount || 0) === 0) {
        return;
      }

      subType &&
        displaySubItems.push({
          name,
          amount,
        });
    });

  displaySubItems.push({
    name: "BILLING_AMOUNT",
    amount: billingAmountWithTax,
  });

  return displaySubItems;
};

interface SubItemProps {
  data: ISubItem;
  className?: string;
}

function SubItem({ data, className }: SubItemProps) {
  const label =
    data.name && CHECKOUT_SUM_UP_SUB_ITEM_NAME_TO_LABEL_MAP[data.name];

  return label ? (
    <div className={`sumup__subItem ${className || ""}`}>
      <span className="label">{label}</span>
      <span className="number">{formatCurrency(data.amount)}</span>
    </div>
  ) : null;
}

interface SumUpProps {
  sumUp?: ISumUp | null;
  promotions?: string[];
}

function SumUp({ sumUp, promotions }: SumUpProps) {
  const items = selectSumUpItems(sumUp);
  if (!items.length) return <div className="sumup" />;

  // the last item is always the billing amount
  const billingAmount = items.pop()!;

  return (
    <div className="sumup">
      {items.map((item, idx) => (
        <SubItem data={item} key={`sub-item-${idx}`} />
      ))}

      {promotions?.length ? (
        <ul className="sumup__promotions">
          {promotions.map((name, idx) =>
            name ? (
              <li key={name + "-" + idx}>
                <span className="promotion-name">{name}</span>
                <span className="promotion-applied">適用</span>
              </li>
            ) : null
          )}
        </ul>
      ) : null}

      <div className="divider" />
      <SubItem data={billingAmount} className="billing-amount" />
    </div>
  );
}

export { SumUp, selectSumUpItems };
